import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { addNotification } from "../../../Components/Notification";

import Axios from "../../../Database/Axios";
import Spin from "../../../Assets/SVG/Spin";
import Input from "../../../Components/Input/Input";
import format from "./RowFormat.json";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const checkInput = () => {
    if (!formData.username) {
      addNotification("Empty field", "please enter a username", "warning");
      return false;
    }
    if (!formData.password) {
      addNotification("Empty field", "please enter a password", "warning");
      return false;
    }
    // if(password.length < 8){
    //   addNotification('Password too short','password should be at least 8 characters long','warning');
    //   return false;
    // }
    return true;
  };

  const callFunction = async () => {
    setLoading(true);
    console.log(formData);

    if (!checkInput()) {
      setLoading(false);
      return false;
    }

    try {
      const response = await Axios.post("/login/", formData);
      console.log(response);
      if (response.data.status) {
        addNotification(
          "Check email box",
          "Please enter OTP",
          "success"
        );
        const address = "/otp/" + formData.username;
        navigate(address, { state: { fromAdmin: false, data: formData } });
      } else {
        addNotification("Check email box", response.data.message, "warning");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        addNotification(
          "Error",
          error.response.data.message || "An error occurred",
          "warning"
        );
      } else if (error.request) {
        addNotification("Error", "No response received from server", "warning");
      } else {
        addNotification(
          "Error",
          error.message || "An unknown error occurred",
          "warning"
        );
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] min-h-dvh p-3 ">
      <div className="max-w-[400px] w-[400px] min-w-[200px]">
        <div className="bg-white shadow-md rounded-md p-5 ">
          <h1 className="text-center text-8xl font-bold font-mono text-orange-500">
            Login
          </h1>
          <div className="grid-cols-1 grid gap-x-4 sm:gap-y-1">
            {format.map((field, index) => (
              <div key={index}>
                <p>{field.title}</p>
                <Input
                  defaultValue={formData[field.name]}
                  onChange={handleChange}
                  name={field.target}
                  type={field.type}
                  placeholder={field.placeholder}
                />
              </div>
            ))}
          </div>
          <div className="float-right">
            <Link
              to={"/forgot-password"}
              className="text-black hover:text-gray-700"
            >
              Forgot Password?
            </Link>
          </div>
          <br />
          {!loading && (
            <button
              className="h-10 rounded-sm bg-orange-500 w-full text-white text-xl hover:bg-orange-600 outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
              onClick={callFunction}
            >
              Continue
            </button>
          )}
          {loading && (
            <button className="h-10 rounded-sm bg-orange-500 w-full text-white text-xl hover:bg-orange-600 outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500 flex items-center justify-center">
              <Spin />
            </button>
          )}
        </div>
        <br />
        <Link to="/create-account">
          <p className="text-center cursor-pointer">
            Don't have account!. Create a account
          </p>
        </Link>
      </div>
    </div>
  );
}
