import axios from "axios";
const Axios = axios.create({
  // baseURL: 'http://localhost:8000/api/',  
  baseURL: 'https://adde-152-58-35-148.ngrok-free.app/api/',
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "ngrok-skip-browser-warning": "true",
  },
  withCredentials: true,  
});

export default Axios;
